/* ========================================================================= *\
 * Template Downloads
\* ========================================================================= */
#template-download {
    .template-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .template-column {
            width: 25%;
        }
   }
}


  
/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media(max-width:800px) {
    #template-download {
        h1 {
            text-align: center;
        }
        .template-content {
            text-align: center;
            .template-column {
                width: 50%;
            }
        }
    }
}