/* ========================================================================= *\
 * Become A Dealer 
\* ========================================================================= */
#become-a-dealer {
    label {
        padding-top: 10px;
    }
    .col-md-12 {
        padding: 0px;
    }
    .issuuembed {
        padding-top: 10px;
        padding-bottom: 10px;
        margin: auto;
          max-width: 100%;


    }    
    input[type=submit] {
        background: $dark-teal;
        color: $white;
        &:hover {
            background: $teal;
        }
    }
    .checkbox,
    .radio {
        margin-top: auto;
        margin-bottom: auto;
        display: inline-block;
        position: auto;
    }
    .radio {
        padding-right: 10px;
        
    }
    #upgradeForm {
        .upgradeCopy {
            width: 500px;
        }
        label {
            padding-left: 10px;
        }
        .radio {
            padding-right: 10px;        
        }
    }
    .preferredcontactmethodcopy {
        width: 190px;
    }
    #becomeadealercarousel {
        padding: 15px;
    }
    .carousel-control.left,
    .carousel-control.right {
        background-image: none;
    }
} 

#becomeADealer {
    margin: auto;
}