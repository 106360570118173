/* ========================================================================= *\
 * Price List
\* ========================================================================= */
#price-list {
    ul {
        padding: 0 0 12px 0;
        li {
            padding-top: 10px;
            font-size: 15px;
        }
    }
}
