/* ========================================================================= *\
 * Footer
\* ========================================================================= */

#footer{
    padding-top:10px;
    padding-bottom:10px;
    color:#ffffff;
    padding-left: 10px;
    padding-right:10px;
    .footer-links-width {
        width: 80%;
    }
    .footer-row {
        display: flex;
    }
}

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media(max-width:600px) {
    #footer {
        .footer-row {
            display: block;
            text-align: center;
            .flex-space-evenly {
                display: block;
                #DigiCertClickID_p3N9J3Cn {
                    align: left;
                }
            }
            .footer-links-width {
                width: 100%;
            }
            
            h4 {
                min-height: 35px;
            }
        }
    }
}