/* ========================================================================= *\
 * Get The Look
\* ========================================================================= */
.get-the-look-container {
    height: 350px;
}

.get-the-look-text {
    font-size: 12px;
    padding: 10px;
}