/* ========================================================================= *\
 * Custom Art
\* ========================================================================= */
#customArt {
    a {
        color: $dark-teal;
        &:hover {
            color: $teal;
        }
    }
    #customArtExamples {
        display: flex;
    }
}