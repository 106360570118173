/* ========================================================================= *\
 * Share/Fave/Pinit Buttons
\* ========================================================================= */
.share-saved-designs {
    width:100px; 
    margin: auto;
}
.faveButton {
    background-image: url("/images/fave.png");
    background-repeat: no-repeat;
    vertical-align: top;
}

.shareButton {
    background-image: url("/images/share.png");
    background-repeat: no-repeat;
}

.shareButton img {
    width: 1px !important;
    height: 1px !important;
    float: left;
    vertical-align: top;
}

.unfaveButton {
    background-image: url("/images/unfave.png");
    vertical-align: top;
    background-repeat: no-repeat;
}

.pinitButton {
    background-image: url("/images/pinit.png");
    background-repeat: no-repeat;
    vertical-align: top;
}

.faveButton, 
.unfaveButton,
.shareButton {
    border: none;
    float: left;
    height: 24px;
    width: 45px;
    margin-right: 6px;
    background-color: #FFF;
    background-size: 100% auto;
}

.pinitButton {
    border: none;
    float: left;
    height: 24px;
    width: 41px;
    margin-right: 6px;
    background-color: #FFF;
    background-size: 100% auto;
}