/* ========================================================================= *\
 * Saved Designs
\* ========================================================================= */
#ordered-designs,
#saved-designs {
    h2 {
        font-size: 35px;
    }
    form {
        .btn {
            background: $dark-teal;
            &:hover {
                background: $teal;
            }
        }
        input {
            margin: 0;
        }
    }
    .savedDesign {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            width:110px;
            margin-bottom:5px;
            margin-right:5px;
            background-color: $dark-teal;
            text-align: center;
          
            &:hover {
                background: $teal;
            }
        }
    }
    .col-md-4 {
        padding: 0;
    }
    #design-search {
        text-align: center;
        form {
            padding-bottom: 15px;
        }
        
        label {
            font-size: 25px;
            padding-bottom: 15px;
        }
    }
}