/* ========================================================================= *\
 * Utilities 
\* ========================================================================= */
 
.trans {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
 .full-width {
    width:100%;
}
.pad-top{
    padding-top:10px;
}
.pad-top-small {
    padding-top: 7px;
}
.pad-left{
    padding-left:10px;
}
.pad-left-medium {
    padding-left: 20px;
}
.pad-right { 
    padding-right: 10px;
}

.pad-bottom {
    padding-bottom: 10px;
} 

.pad-all {
    padding: 10px;
}

.pad-all-big {
    padding: 20px;
}

.no-pad-left {
    padding-left: 0px;
}

.no-pad-right {
    padding-right: 0px;
}

.margin-left {
    margin-left: 10px;
}

.margin-right {
    margin-right: 10px;
}
.margin-vertical-center {
    margin-top: auto;
    margin-bottom: auto;
}
.align-left {
    text-align: left;
}
.clear{
    padding-top:10px;
    padding-bottom:10px;
}
.clear-small{
    padding-top:2px;
    padding-bottom: 2px;
}
.text-small {
    font-size: 10px;
}
.pad-img{
    padding:10px;
    float:left;
}
.med-text{
    font-size: 35px;
}
.bigtext{
    font-size:50px;
	font-family: Noto Sans;
	line-height: auto;
}
.bigLI  {
    font-size: 16px;
}
.bigger {
    font-size: 20px;
}
#wo-breadcrumbs li{
    display:inline-block;            
    list-style-type:none;
    padding:2px;
}

.ui-accordion .ui-accordion-content-active { overflow: auto !important; }

.errormessage {
    color:red !important;    
}

.no-float {
    float:none !important;
}
.as-h4 {
    display: block;
    font-size: 18px;
	padding-top: 5px;
}
.row-adjust {
    position: relative;
    left: 15px;
}

.error {
    color:red;
    font-size: 14px;
}  

.flex-space-between {
    display: flex;
    justify-content: space-between;
}


.flex-center {
    display: flex;
    justify-content: center;
}

.flex-space-evenly {
    display: flex;
    justify-content: space-evenly;
}


/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media (max-width: 1000px) {
    .border{
        padding:4px;
    }
}

@media(max-width:800px) {
    .pad-right.col-md-6 {
        padding-right: 0;
    }
}