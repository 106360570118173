@import "utilities";

/* ========================================================================= *\
 * Colors
\* ========================================================================= */
$black: #000000;
$white: #ffffff;
$dark-grey: #3a3a3c;
$medium-grey: #676767;
$grey: #888888;
$light-grey: #d9d9d9;
$pink: #EB7374;    
$light-red: #EB7374;
$light-pink: #FADCDC;
$dark-pink: #C8244E;
$error-message: #F0DDDD;
$reddish-grey: #928985;
$reddish-brown: #54372B;
$dark-teal: #31708E;
$teal: #539FBC;
$green: #00AA00;
$red: #ff0000;
$dark-red: #B6263D;
$light-red: #EB7374; 
$yellow: #FFFF00;
$yellow-grey: #f5f4f0;
$light-yellow: #fffee5;


/* ========================================================================= *\
 * Text
\* ========================================================================= */
.btn.white-text,
a.white-text,
a.white-text:visited,
.white-text {
    color: $white;
    a,
    a:hover,
    a:link,
    a:visited,
    hover,
    link,
    visited   {
        color: $white;
        text-decoration-color: $white;;
    }
}

.btn.dark-grey-text,
a.dark-grey-text, 
.dark-grey-text {
    color: $dark-grey;
    a,
    a:hover,
    a:link,
    a:visited  {
        color: $dark-grey;
        text-decoration-color: $dark-grey;
    }
}

.btn.dark-blue-text,
a.dark-blue-text,
.dark-blue-text {
    color: $dark-teal;
    a,
    a:hover,
    a:link,
    a:visited  {
        color: $dark-teal;
        text-decoration-color: $dark-teal ;
    }
}

.btn.teal-text,
a.teal-text,
.teal-text {
    color: $teal;
    a,
    a:hover,
    a:link,
    a:visited  {
        color: $teal;
        text-decoration-color: $teal;
    }
}

.dark-red-text {
    color: $dark-red; 
}

.green-text {
    color: $green;
}

.red-text {
    color: $red;
}

.black-text {
    color: $black;
    a,
    a:hover,
    a:link,
    a:visited {
        color: $black;
    }
}
/* ========================================================================= *\
 * Buttons
\* ========================================================================= */

.dark-blue-button {
    background: $dark-teal;
    color: $white;
    &:hover {
        background: $teal;
        color: $white;
    }
}

/* ========================================================================= *\
 * Backgrounds
\* ========================================================================= */
.grey-bg {
    background: $grey;
    }

.dark-grey-bg {
    background: $dark-grey;
}

.dark-blue-bg {
    background: $dark-teal;
}

/* ========================================================================= *\
 * Other
\* ========================================================================= */

.dark-grey-line{
    background: $dark-grey;
    height: 2px;
}

/* ========================================================================= *\
 * Borders
\* ========================================================================= */
.dark-grey-border {
    border: 1px dashed $dark-grey;
}
/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media (max-width: 1000px) {
    .dark-grey-line {
        display: none;
    }
}