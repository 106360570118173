/* ========================================================================= *\
 * Utilities 
\* ========================================================================= */
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
.trans, input[type=submit], a, .ar-en-masthead img, .ar-en-masthead img:hover, #best-selling-products img, #best-selling-products img:hover, #slider .sliderimage, #slider h4 span, #slider h4 span:hover, .category-container img, .category-container img:hover, .menudropdownblock .dropdown-menu, .dropdown:hover .dropdown-menu, .btn, .ui-widget .ui-dialog-buttonset button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.full-width {
  width: 100%; }

.pad-top {
  padding-top: 10px; }

.pad-top-small {
  padding-top: 7px; }

.pad-left {
  padding-left: 10px; }

.pad-left-medium {
  padding-left: 20px; }

.pad-right {
  padding-right: 10px; }

.pad-bottom {
  padding-bottom: 10px; }

.pad-all {
  padding: 10px; }

.pad-all-big {
  padding: 20px; }

.no-pad-left {
  padding-left: 0px; }

.no-pad-right {
  padding-right: 0px; }

.margin-left {
  margin-left: 10px; }

.margin-right {
  margin-right: 10px; }

.margin-vertical-center {
  margin-top: auto;
  margin-bottom: auto; }

.align-left {
  text-align: left; }

.clear {
  padding-top: 10px;
  padding-bottom: 10px; }

.clear-small {
  padding-top: 2px;
  padding-bottom: 2px; }

.text-small {
  font-size: 10px; }

.pad-img {
  padding: 10px;
  float: left; }

.med-text {
  font-size: 35px; }

.bigtext {
  font-size: 50px;
  font-family: Noto Sans;
  line-height: auto; }

.bigLI {
  font-size: 16px; }

.bigger {
  font-size: 20px; }

#wo-breadcrumbs li {
  display: inline-block;
  list-style-type: none;
  padding: 2px; }

.ui-accordion .ui-accordion-content-active {
  overflow: auto !important; }

.errormessage {
  color: red !important; }

.no-float {
  float: none !important; }

.as-h4 {
  display: block;
  font-size: 18px;
  padding-top: 5px; }

.row-adjust {
  position: relative;
  left: 15px; }

.error {
  color: red;
  font-size: 14px; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center {
  display: flex;
  justify-content: center; }

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 1000px) {
  .border {
    padding: 4px; } }

@media (max-width: 800px) {
  .pad-right.col-md-6 {
    padding-right: 0; } }

/* ========================================================================= *\
 * Fonts
\* ========================================================================= */
/* ========================================================================= *\
 * Utilities 
\* ========================================================================= */
.trans, input[type=submit], a, .ar-en-masthead img, .ar-en-masthead img:hover, #best-selling-products img, #best-selling-products img:hover, #slider .sliderimage, #slider h4 span, #slider h4 span:hover, .category-container img, .category-container img:hover, .menudropdownblock .dropdown-menu, .dropdown:hover .dropdown-menu, .btn, .ui-widget .ui-dialog-buttonset button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.full-width {
  width: 100%; }

.pad-top {
  padding-top: 10px; }

.pad-top-small {
  padding-top: 7px; }

.pad-left {
  padding-left: 10px; }

.pad-left-medium {
  padding-left: 20px; }

.pad-right {
  padding-right: 10px; }

.pad-bottom {
  padding-bottom: 10px; }

.pad-all {
  padding: 10px; }

.pad-all-big {
  padding: 20px; }

.no-pad-left {
  padding-left: 0px; }

.no-pad-right {
  padding-right: 0px; }

.margin-left {
  margin-left: 10px; }

.margin-right {
  margin-right: 10px; }

.margin-vertical-center {
  margin-top: auto;
  margin-bottom: auto; }

.align-left {
  text-align: left; }

.clear {
  padding-top: 10px;
  padding-bottom: 10px; }

.clear-small {
  padding-top: 2px;
  padding-bottom: 2px; }

.text-small {
  font-size: 10px; }

.pad-img {
  padding: 10px;
  float: left; }

.med-text {
  font-size: 35px; }

.bigtext {
  font-size: 50px;
  font-family: Noto Sans;
  line-height: auto; }

.bigLI {
  font-size: 16px; }

.bigger {
  font-size: 20px; }

#wo-breadcrumbs li {
  display: inline-block;
  list-style-type: none;
  padding: 2px; }

.ui-accordion .ui-accordion-content-active {
  overflow: auto !important; }

.errormessage {
  color: red !important; }

.no-float {
  float: none !important; }

.as-h4 {
  display: block;
  font-size: 18px;
  padding-top: 5px; }

.row-adjust {
  position: relative;
  left: 15px; }

.error {
  color: red;
  font-size: 14px; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center {
  display: flex;
  justify-content: center; }

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 1000px) {
  .border {
    padding: 4px; } }

@media (max-width: 800px) {
  .pad-right.col-md-6 {
    padding-right: 0; } }

/* ========================================================================= *\
 * Colors
\* ========================================================================= */
/* ========================================================================= *\
 * Text
\* ========================================================================= */
.btn.white-text, .ui-widget .ui-dialog-buttonset button.white-text,
a.white-text,
a.white-text:visited,
.white-text {
  color: #ffffff; }
  .btn.white-text a, .ui-widget .ui-dialog-buttonset button.white-text a,
  .btn.white-text a:hover,
  .ui-widget .ui-dialog-buttonset button.white-text a:hover,
  .btn.white-text a:link,
  .ui-widget .ui-dialog-buttonset button.white-text a:link,
  .btn.white-text a:visited,
  .ui-widget .ui-dialog-buttonset button.white-text a:visited,
  .btn.white-text hover,
  .ui-widget .ui-dialog-buttonset button.white-text hover,
  .btn.white-text link,
  .ui-widget .ui-dialog-buttonset button.white-text link,
  .btn.white-text visited,
  .ui-widget .ui-dialog-buttonset button.white-text visited,
  a.white-text a,
  a.white-text a:hover,
  a.white-text a:link,
  a.white-text a:visited,
  a.white-text hover,
  a.white-text link,
  a.white-text visited,
  a.white-text:visited a,
  a.white-text:visited a:hover,
  a.white-text:visited a:link,
  a.white-text:visited a:visited,
  a.white-text:visited hover,
  a.white-text:visited link,
  a.white-text:visited visited,
  .white-text a,
  .white-text a:hover,
  .white-text a:link,
  .white-text a:visited,
  .white-text hover,
  .white-text link,
  .white-text visited {
    color: #ffffff;
    text-decoration-color: #ffffff; }

.btn.dark-grey-text, .ui-widget .ui-dialog-buttonset button.dark-grey-text,
a.dark-grey-text,
.dark-grey-text {
  color: #3a3a3c; }
  .btn.dark-grey-text a, .ui-widget .ui-dialog-buttonset button.dark-grey-text a,
  .btn.dark-grey-text a:hover,
  .ui-widget .ui-dialog-buttonset button.dark-grey-text a:hover,
  .btn.dark-grey-text a:link,
  .ui-widget .ui-dialog-buttonset button.dark-grey-text a:link,
  .btn.dark-grey-text a:visited,
  .ui-widget .ui-dialog-buttonset button.dark-grey-text a:visited,
  a.dark-grey-text a,
  a.dark-grey-text a:hover,
  a.dark-grey-text a:link,
  a.dark-grey-text a:visited,
  .dark-grey-text a,
  .dark-grey-text a:hover,
  .dark-grey-text a:link,
  .dark-grey-text a:visited {
    color: #3a3a3c;
    text-decoration-color: #3a3a3c; }

.btn.dark-blue-text, .ui-widget .ui-dialog-buttonset button.dark-blue-text,
a.dark-blue-text,
.dark-blue-text {
  color: #31708E; }
  .btn.dark-blue-text a, .ui-widget .ui-dialog-buttonset button.dark-blue-text a,
  .btn.dark-blue-text a:hover,
  .ui-widget .ui-dialog-buttonset button.dark-blue-text a:hover,
  .btn.dark-blue-text a:link,
  .ui-widget .ui-dialog-buttonset button.dark-blue-text a:link,
  .btn.dark-blue-text a:visited,
  .ui-widget .ui-dialog-buttonset button.dark-blue-text a:visited,
  a.dark-blue-text a,
  a.dark-blue-text a:hover,
  a.dark-blue-text a:link,
  a.dark-blue-text a:visited,
  .dark-blue-text a,
  .dark-blue-text a:hover,
  .dark-blue-text a:link,
  .dark-blue-text a:visited {
    color: #31708E;
    text-decoration-color: #31708E; }

.btn.teal-text, .ui-widget .ui-dialog-buttonset button.teal-text,
a.teal-text,
.teal-text {
  color: #539FBC; }
  .btn.teal-text a, .ui-widget .ui-dialog-buttonset button.teal-text a,
  .btn.teal-text a:hover,
  .ui-widget .ui-dialog-buttonset button.teal-text a:hover,
  .btn.teal-text a:link,
  .ui-widget .ui-dialog-buttonset button.teal-text a:link,
  .btn.teal-text a:visited,
  .ui-widget .ui-dialog-buttonset button.teal-text a:visited,
  a.teal-text a,
  a.teal-text a:hover,
  a.teal-text a:link,
  a.teal-text a:visited,
  .teal-text a,
  .teal-text a:hover,
  .teal-text a:link,
  .teal-text a:visited {
    color: #539FBC;
    text-decoration-color: #539FBC; }

.dark-red-text {
  color: #B6263D; }

.green-text {
  color: #00AA00; }

.red-text {
  color: #ff0000; }

.black-text {
  color: #000000; }
  .black-text a,
  .black-text a:hover,
  .black-text a:link,
  .black-text a:visited {
    color: #000000; }

/* ========================================================================= *\
 * Buttons
\* ========================================================================= */
.dark-blue-button {
  background: #31708E;
  color: #ffffff; }
  .dark-blue-button:hover {
    background: #539FBC;
    color: #ffffff; }

/* ========================================================================= *\
 * Backgrounds
\* ========================================================================= */
.grey-bg {
  background: #888888; }

.dark-grey-bg {
  background: #3a3a3c; }

.dark-blue-bg {
  background: #31708E; }

/* ========================================================================= *\
 * Other
\* ========================================================================= */
.dark-grey-line {
  background: #3a3a3c;
  height: 2px; }

/* ========================================================================= *\
 * Borders
\* ========================================================================= */
.dark-grey-border {
  border: 1px dashed #3a3a3c; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 1000px) {
  .dark-grey-line {
    display: none; } }

/* ========================================================================= *\
 * Tags
\* ========================================================================= */
body {
  font-family: Noto Sans; }

.h1, h1 {
  display: block;
  font-size: 30px;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-weight: bold;
  font-family: Fjalla One; }

.h2, h2 {
  display: block;
  font-size: 21px;
  font-family: Fjalla One; }

.h3, h3 {
  display: block;
  font-size: 24px;
  font-family: Fjalla One; }

.h4, h4 {
  display: block;
  font-size: 18px;
  font-family: Fjalla One; }

.h5, h5 {
  display: block;
  font-size: 14px;
  font-family: Fjalla One;
  color: #222222; }

.h6, h6 {
  display: block;
  font-size: 12px;
  font-family: Fjalla One;
  color: #555555; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 1000px) {
  body {
    padding-top: 50px; } }

/* ========================================================================= *\
 * Jquery
\* ========================================================================= */
.ui-widget .ui-dialog-titlebar-close {
  display: none; }

.ui-widget .ui-dialog-buttonset button {
  background: #31708E;
  color: #ffffff;
  border: none; }
  .ui-widget .ui-dialog-buttonset button:hover {
    background: #539FBC; }

.ui-dialog {
  position: fixed;
  left: 40% !important;
  top: 38% !important; }

.datepicker table tr td.active.active {
  background-color: #539FBC !important; }

/* ========================================================================= *\
 * Masthead
\* ========================================================================= */
.ar-en-masthead {
  display: flex;
  justify-content: center; }
  .ar-en-masthead p {
    margin: 0px; }
  .ar-en-masthead img:hover {
    opacity: .7; }
  .ar-en-masthead h3.header-menu-full a {
    position: relative;
    top: -15px; }
  .ar-en-masthead .news-link {
    color: #3a3a3c; }
    .ar-en-masthead .news-link:hover {
      color: #31708E; }
  .ar-en-masthead .more-info a:hover {
    background: #31708E;
    text-decoration: none; }
  .ar-en-masthead .dark-blue-text:hover {
    color: #539FBC; }

/* ========================================================================= *\
 * Nav Items
\* ========================================================================= */
.menuContainter {
  display: flex;
  justify-content: space-between; }

a.menuCategory {
  margin: auto;
  z-index: 1000; }

.navbar {
  border: none; }

.nav.navbar-nav {
  font-family: Noto Sans;
  font-size: 12px; }

.form-control.header {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important; }

.nav > li.fix > a {
  padding: 15px 5px !important;
  margin-right: 0 !important; }

.menudropdownblock {
  display: flex;
  background: #3a3a3c;
  min-height: 50px;
  font-size: 11px;
  line-height: 13px;
  font-family: Noto Sans;
  width: 140px;
  padding: 4px;
  text-align: center;
  z-index: 1; }

a.menuCategory {
  display: block;
  color: #ffffff; }

.menuCategory:visited {
  color: #ffffff; }

.menudropdownblock:hover,
.menudropdownblock:hover > .menuCategory:link,
.menudropdownblock:hover > .menuCategory:visited {
  background: #31708E;
  color: #ffffff;
  text-decoration: none; }

.menudropdownblock .submenu a {
  color: #000000; }

.header-li-right {
  padding-right: 20px; }

ul.nav li a, ul.nav li a:visited {
  color: #31708E !important; }

ul.nav li a:hover, ul.nav li a:active {
  color: #3a3a3c !important; }

ul.nav li.active a {
  color: #31708E !important; }

.collapse.navbar-collapse {
  background: #ffffff; }

.collapse.navbar-collapse.nav-right {
  padding-left: 10px; }

.nav > li > a.social-icons {
  padding: 0px 10px 0px 0px; }

.float-nav-left a:link,
.float-nav-left a:visited {
  color: #3a3a3c;
  text-decoration: none; }

.float-nav-left {
  float: left; }
  .float-nav-left a:hover {
    color: #31708E; }

#menu-nav-napkins-matches-and-barware {
  display: none; }

#menu-nav-favors-and-accessories {
  display: none; }

#menu-nav-tabletop-and-stationery {
  display: none; }

#menu-nav-save-the-dates {
  display: none; }

#menu-nav-shop-by-occasion {
  display: none; }

#menu-nav-gifts {
  display: none; }

#menu-nav-get-the-look {
  display: none; }

#menu-nav-ideas-and-tips {
  display: none; }

.menu, ol, ul {
  font-size: 12px;
  padding: 0 0 0 5px;
  text-decoration: none;
  list-style: none; }

.nav-cat-main .h3 {
  color: #31708E; }

.button, input, select, textarea {
  padding: 5px;
  margin-top: 5px; }

.pad-top-header {
  padding-top: 20px; }

.can-coolers {
  top: 55px !important; }

.stir-sticks {
  top: 85px !important; }

.gift-set {
  top: 65px !important; }

.quick-ship {
  top: 79px !important; }

.blank-napkins {
  top: 85px !important; }

.active > .dark-blue-bg {
  background: #539FBC; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (min-width: 1200px) {
  .menudropdownblock {
    width: 175px;
    font-size: 12px; } }

@media (max-width: 1200px) {
  .float-nav-left {
    font-size: 10px; } }

@media (max-width: 1000px) {
  .nav-cat-main {
    height: 400px; }
  .nav-cat-links {
    display: none; }
  .account-options {
    justify-content: center; }
  .more-info a {
    display: block;
    width: 125px;
    text-align: center; } }

@media (max-width: 800px) {
  .li-responsive-header {
    padding-right: 10px !important; } }

@media (max-width: 600px) {
  .ar-en-masthead {
    padding-top: 20px; }
    .ar-en-masthead p {
      font-size: 10px; }
    .ar-en-masthead .pad-all {
      padding: 5px; }
  .more-info {
    text-align: center; }
    .more-info a {
      width: 50%;
      margin: auto; }
    .more-info .pad-all {
      display: block;
      text-align: center; } }

/* ========================================================================= *\
 * Home Page
\* ========================================================================= */
.homepage-message p {
  font-size: 16px;
  color: #ffffff;
  max-width: 1200px;
  margin: 0; }

.homepage-message a {
  color: #ffffff;
  text-decoration: none; }

#our-services h2,
#our-services h4 {
  font-family: Noto Sans; }

#our-services .border {
  border: 1px solid #000000;
  padding: 10px; }

#our-services .services-outer-box {
  height: 150px; }

#our-services .services-inner-box {
  height: 80px;
  font-size: 12px; }

#aboutus h1 {
  font-family: Noto Sans; }

#best-selling-products h2 b,
#best-selling-products h2 i,
#best-selling-products h4 b,
#best-selling-products h4 i {
  font-family: Noto Sans; }

#best-selling-products img:hover {
  opacity: .8; }
  #best-selling-products img:hover + .ccadjust .shop-now {
    background: #539FBC; }

#best-selling-products .best-selling-padding {
  padding-top: 25px; }

#best-selling-products .best-selling-link {
  color: #404040;
  font-size: 25px;
  font-weight: bold; }

#best-selling-products .best-sellers-header b {
  font-size: 20px;
  font-family: Noto Sans; }

#best-selling-products .best-sellers-header a {
  text-decoration: none; }

#best-selling-products .best-sellers-header a:hover {
  border-bottom: 2px solid #3a3a3c;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px; }

#best-selling-products .best-selling-description {
  height: 25px; }

#best-selling-products .shop-now {
  font-size: 22px;
  background: #31708E;
  padding-top: 1%;
  padding-bottom: 2%;
  font-family: Noto Sans; }

#best-selling-products .carousel-caption {
  bottom: 85px; }

#new-products {
  padding-top: 25px; }
  #new-products h2,
  #new-products h4 {
    font-family: Noto Sans; }
  #new-products .products-row {
    display: flex;
    position: relative;
    top: -35px; }
  #new-products .new-product {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px; }
    #new-products .new-product a {
      padding: 10px;
      text-decoration: none; }
      #new-products .new-product a p {
        font-size: 16px;
        font-family: Noto Sans;
        padding: 15px;
        margin: 0; }
      #new-products .new-product a .copy-bg {
        width: 100%;
        margin: auto;
        position: relative;
        top: 75px;
        background: #3a3a3c; }
      #new-products .new-product a:hover .copy-bg {
        background: #31708E; }

#slider .sliderimage {
  color: #ffffff !important;
  font-size: 30px;
  position: relative;
  top: 4px; }

#slider .sliderimage:hover {
  color: #ffffff;
  text-decoration: none; }

#slider .carousel-caption.ccadjust {
  bottom: 80px; }

#slider .carousel-indicators {
  bottom: 3px; }

#slider h4 span {
  background: #3a3a3c; }
  #slider h4 span:hover {
    background: #31708E; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 1200px) {
  #aboutus .grey-bg {
    padding-top: 10px;
    padding-bottom: 10px; }
  #best-selling-products img {
    margin-top: -25px; }
  #best-selling-products .ccadjust {
    padding-bottom: 75px; }
  #best-selling-products .carousel-caption {
    bottom: 55px; }
  #best-selling-products .best-selling-padding {
    height: 120px;
    padding-top: 15px; } }

@media (max-width: 1000px) {
  #our-services .col-md-3.col-sm-6 {
    padding-bottom: 15px; }
  #new-products .new-product .new-products-copy p {
    font-size: 10px; }
  #slider .sliderimage {
    font-size: 10px;
    top: -2px; }
  #slider .carousel-indicators li {
    display: none; }
  #best-selling-products img {
    width: 360px;
    margin-top: -25px; }
  #best-selling-products .ccadjust {
    padding-bottom: 0px; }
  #best-selling-products .carousel-caption {
    bottom: 115px; }
  #best-selling-products .best-selling-padding {
    height: auto; }
    #best-selling-products .best-selling-padding .best-selling-product-name {
      height: auto; }
  #best-selling-products .shop-now {
    width: 200px;
    position: relative;
    margin: auto; } }

@media (max-width: 800px) {
  #message .homepage-message p {
    font-size: 12px; }
  #homePageSlider h3 {
    font-size: 14px;
    padding-bottom: 5px; }
  #best-selling-products .best-selling-padding {
    padding-top: 10px; }
  #new-products .products-row {
    flex-direction: column; }
    #new-products .products-row .new-product img,
    #new-products .products-row .new-product .copy-bg {
      max-width: 360px;
      width: 360px; }
    #new-products .products-row .new-product p {
      font-size: 20px; } }

@media (max-width: 750px) {
  #best-selling-products .best-selling-padding {
    padding-top: 35px; }
  #slider .sliderimage {
    font-size: 14px; } }

@media (max-width: 320px) {
  #slider #homePageSlider h3 {
    font-size: 10px; }
  #slider #homePageSlider > ol > li {
    display: none; }
  #best-selling-products .shop-now {
    width: 100px;
    font-size: 12px; } }

/* ========================================================================= *\
 * Product Variants
\* ========================================================================= */
#nav-category .col-md-12, #nav-category .col-md-6, #nav-category .col-md-4 {
  padding: 0; }
  #nav-category .col-md-12 :first-child, #nav-category .col-md-6 :first-child, #nav-category .col-md-4 :first-child {
    padding-right: 10px; }
  #nav-category .col-md-12 img, #nav-category .col-md-6 img, #nav-category .col-md-4 img {
    padding-bottom: 5px; }

#nav-category .nav-cat-main {
  font-size: 13px; }
  #nav-category .nav-cat-main p {
    margin: 0px;
    max-width: 525px;
    min-height: 75px; }

#nav-category .nav-cat-links {
  min-height: 200px;
  max-width: 450px;
  padding-top: 5px;
  display: flex; }
  #nav-category .nav-cat-links .variant-link {
    display: block;
    font-size: 11px;
    line-height: 11px;
    font-family: Noto Sans;
    color: #3a3a3c; }

#category .col-md-4, #category .col-sm-6 {
  padding: 0; }

#category .shop-now-button {
  background: #31708E; }
  #category .shop-now-button:hover {
    background: #539FBC; }

.cups-info-link {
  font-weight: normal;
  font-size: 14px;
  color: #3a3a3c; }
  .cups-info-link:visited {
    color: #3a3a3c; }

#variant-designs .col-md-12,
#variant-designs .col-md-4 {
  padding-left: 0px;
  padding-right: 0px; }

#variant-designs .or-text {
  position: relative;
  top: 20px;
  font-size: 35px;
  margin: auto; }

#variant-designs .productVariantEnlarge {
  float: right; }

#variant-designs .productVariantButtons {
  display: block;
  margin: auto;
  height: 20px;
  width: 150px; }

#variant-designs .variant-header {
  font-family: 25px;
  background: #d9d9d9;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #3a3a3c;
  margin: auto;
  left: 3px; }

#variant-designs #narrowChoices h3 {
  color: #31708E;
  font-size: 20px; }

#variant-designs .tagName {
  color: #31708E;
  font-size: 16px; }

#variant-designs .tagList {
  font-size: 12px;
  text-indent: 20px; }
  #variant-designs .tagList a {
    color: #3a3a3c; }
    #variant-designs .tagList a:hover {
      color: #31708E; }

#variant-designs .design-make-header {
  background: #d9d9d9;
  color: #3a3a3c; }

#variant-designs .pv-container {
  height: 420px; }

#variant-designs .tagCategories {
  background: #d9d9d9;
  border-radius: 10px;
  padding: 10px;
  width: 120%;
  position: relative;
  left: -10px; }

#variant-designs .createYourOwnBar {
  background: #d9d9d9;
  width: auto;
  padding: 10px;
  height: auto; }
  #variant-designs .createYourOwnBar button {
    background: #31708E; }
    #variant-designs .createYourOwnBar button:hover {
      background: #539FBC; }
  #variant-designs .createYourOwnBar a {
    color: #3a3a3c; }
    #variant-designs .createYourOwnBar a:hover {
      color: #31708E; }

#variant-designs .make-it-yours button {
  background: #31708E; }
  #variant-designs .make-it-yours button:hover {
    background: #539FBC; }

.productVariantControlsHolder {
  margin-bottom: 10px; }

.category-container {
  min-height: 500px;
  height: auto;
  text-align: center; }
  .category-container img {
    padding: 10px;
    width: 100%;
    height: auto; }
    .category-container img:hover {
      opacity: .7; }
  .category-container h3 {
    height: 30px; }
  .category-container .category-info {
    min-height: 100px; }
  .category-container .longer-category-info {
    min-height: 150px;
    padding-bottom: 10px;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px; }

.back-to-top .greybg:hover {
  background: #d9d9d9; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 1200px) {
  #nav-category .nav-cat-main {
    height: auto; }
  #nav-category .nav-cat-links {
    padding-top: 15px; }
  #nav-category .col-md-12, #nav-category .col-md-6, #nav-category .col-md-4 {
    padding-right: 20px; } }

@media (max-width: 1000px) {
  #nav-category .nav-cat-main {
    height: auto; }
  #nav-category .col-md-12, #nav-category .col-md-6, #nav-category .col-md-4 {
    padding-right: 25px; } }

@media (max-width: 800px) {
  #nav-category .col-md-12, #nav-category .col-md-6, #nav-category .col-md-4 {
    padding: 0; }
    #nav-category .col-md-12 :first-child, #nav-category .col-md-6 :first-child, #nav-category .col-md-4 :first-child {
      padding: 0; }
  #nav-category .nav-cat-main {
    margin: auto; }
    #nav-category .nav-cat-main h3 {
      text-align: center; }
    #nav-category .nav-cat-main p {
      padding-top: 10px; }
    #nav-category .nav-cat-main img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 100%; }
  #category .category-container {
    text-align: center; }
  #category img {
    display: block;
    margin-right: auto;
    margin-left: auto; } }

@media (max-width: 320px) {
  .faveButton, .unfaveButton, .shareButton, .pinitButton {
    border: none;
    float: none;
    width: 22px; }
  .shareButton img {
    float: none; } }

/* ========================================================================= *\
 * Footer
\* ========================================================================= */
#footer {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px; }
  #footer .footer-links-width {
    width: 80%; }
  #footer .footer-row {
    display: flex; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 600px) {
  #footer .footer-row {
    display: block;
    text-align: center; }
    #footer .footer-row .flex-space-evenly {
      display: block; }
      #footer .footer-row .flex-space-evenly #DigiCertClickID_p3N9J3Cn {
        align: left; }
    #footer .footer-row .footer-links-width {
      width: 100%; }
    #footer .footer-row h4 {
      min-height: 35px; } }

/* ========================================================================= *\
 * Affiliate Program
\* ========================================================================= */
#affiliateProgram ul li {
  position: relative;
  left: 35px; }

/* ========================================================================= *\
 * Account Settings
\* ========================================================================= */
#account-settings-header .account-options {
  display: flex;
  flex-flow: row wrap; }
  #account-settings-header .account-options div {
    padding-right: 5px;
    padding-bottom: 25px; }
  #account-settings-header .account-options a:hover {
    background: #539FBC; }

/* ========================================================================= *\
 * Profile Page
\* ========================================================================= */
.profile-pad {
  padding-top: 30px; }

#user_profile p {
  font-size: 13px; }

#user_profile input[type=submit] {
  margin-top: 30px; }

#user_profile .col-md-6 {
  padding: 0; }
  #user_profile .col-md-6 :first-child {
    padding-right: 20px; }

#user_profile a,
#user_profile input[type=submit] {
  background: #31708E; }
  #user_profile a:hover,
  #user_profile input[type=submit]:hover {
    background: #539FBC; }

#changePassword .col-md-6 {
  padding: 0; }

#changePassword .pad-right.col-md-6 {
  padding-right: 10px; }

#changePassword input[type=submit] {
  background: #31708E; }
  #changePassword input[type=submit]:hover {
    background: #539FBC; }

/* ========================================================================= *\
 * Past Orders
\* ========================================================================= */
#past-orders a {
  color: #31708E; }
  #past-orders a:hover {
    color: #539FBC; }

/* ========================================================================= *\
 * Account Settings
\* ========================================================================= */
#account-settings input[type=submit] {
  background: #31708E; }
  #account-settings input[type=submit]:hover {
    background: #539FBC; }

#account-settings label {
  padding-right: 10px; }

/* ========================================================================= *\
 * Reference Documents
\* ========================================================================= */
#documents a {
  color: #31708E; }
  #documents a:hover {
    color: #539FBC; }

/* ========================================================================= *\
 * Price List
\* ========================================================================= */
#price-list a {
  color: #31708E; }
  #price-list a:hover {
    color: #539FBC; }

#loginForm a:hover,
#loginForm input[type=submit]:hover {
  background: #539FBC; }

/* ========================================================================= *\
 * Media Queries
\* ========================================================================= */
@media (max-width: 800px) {
  #user_profile .col-md-6 {
    padding: 0; }
    #user_profile .col-md-6 :first-child {
      padding-right: 0; }
  #account-settings-header .account-options div {
    padding-right: 5px;
    padding-bottom: 25px; } }

/* ========================================================================= *\
 * Gifts Page
\* ========================================================================= */
.gifts-page {
  height: 450px; }

/* ========================================================================= *\
 * Ideas Page
\* ========================================================================= */
.ideas-page {
  height: 650px; }

/* ========================================================================= *\
 * As Seen In Page
\* ========================================================================= */
.asSeenInFix {
  position: relative;
  top: 7px;
  left: 25px; }

/* ========================================================================= *\
 * Saved Designs
\* ========================================================================= */
#ordered-designs h2,
#saved-designs h2 {
  font-size: 35px; }

#ordered-designs form .btn, #ordered-designs form .ui-widget .ui-dialog-buttonset button, .ui-widget .ui-dialog-buttonset #ordered-designs form button,
#saved-designs form .btn,
#saved-designs form .ui-widget .ui-dialog-buttonset button,
.ui-widget .ui-dialog-buttonset #saved-designs form button {
  background: #31708E; }
  #ordered-designs form .btn:hover, #ordered-designs form .ui-widget .ui-dialog-buttonset button:hover, .ui-widget .ui-dialog-buttonset #ordered-designs form button:hover,
  #saved-designs form .btn:hover,
  #saved-designs form .ui-widget .ui-dialog-buttonset button:hover,
  .ui-widget .ui-dialog-buttonset #saved-designs form button:hover {
    background: #539FBC; }

#ordered-designs form input,
#saved-designs form input {
  margin: 0; }

#ordered-designs .savedDesign,
#saved-designs .savedDesign {
  display: flex;
  flex-direction: column;
  align-items: center; }
  #ordered-designs .savedDesign p,
  #saved-designs .savedDesign p {
    width: 110px;
    margin-bottom: 5px;
    margin-right: 5px;
    background-color: #31708E;
    text-align: center; }
    #ordered-designs .savedDesign p:hover,
    #saved-designs .savedDesign p:hover {
      background: #539FBC; }

#ordered-designs .col-md-4,
#saved-designs .col-md-4 {
  padding: 0; }

#ordered-designs #design-search,
#saved-designs #design-search {
  text-align: center; }
  #ordered-designs #design-search form,
  #saved-designs #design-search form {
    padding-bottom: 15px; }
  #ordered-designs #design-search label,
  #saved-designs #design-search label {
    font-size: 25px;
    padding-bottom: 15px; }

/* ========================================================================= *\
 * Price List
\* ========================================================================= */
#price-list ul {
  padding: 0 0 12px 0; }
  #price-list ul li {
    padding-top: 10px;
    font-size: 15px; }

/* ========================================================================= *\
 * Documents
\* ========================================================================= */
#documents ul {
  padding: 0 0 12px 0; }
  #documents ul li {
    padding-top: 10px;
    font-size: 15px; }

/* ========================================================================= *\
 * Orders
\* ========================================================================= */
table#past-orders td, table#past-orders th {
  padding: 10px;
  width: 140px; }

table#past-orders thead th {
  border-bottom: 2px solid #3a3a3c; }

/* ========================================================================= *\
 * Editor  
\* ========================================================================= */
.info-box-pad {
  padding-bottom: 10px; }

#button-editor-help {
  position: relative;
  left: 75%; }
  #button-editor-help .btn:hover, #button-editor-help .ui-widget .ui-dialog-buttonset button:hover, .ui-widget .ui-dialog-buttonset #button-editor-help button:hover {
    background: #539FBC; }

h3.showLayerName.ui-accordion-header.ui-helper-reset.ui-state-default.ui-corner-all,
h3.showLayerName.ui-accordion-header.ui-helper-reset.ui-state-active.ui-corner-top {
  height: 50px; }

#editor .sectionTitle .caption,
#editor .colorChoices .caption {
  padding-left: 20px !important; }

#editor .sectionTitle .image,
#editor .sectionTitle .img,
#editor .colorChoices .image,
#editor .colorChoices .img {
  width: 110px; }

#productTabs .colorChoices img {
  border: 1px solid black; }

/* ========================================================================= *\
 * Pagination  
\* ========================================================================= */
.pagination .current {
  background: #31708E;
  color: #ffffff;
  padding: 2px; }
  .pagination .current:hover {
    background: #539FBC; }

.pagination a {
  color: #3a3a3c; }
  .pagination a:hover {
    color: #539FBC; }

/* ========================================================================= *\
 * Pop Up Store
\* ========================================================================= */
.popUpStoreItem {
  height: 400px; }

.magazine {
  position: relative !important;
  left: 25px;
  top: 7px; }

/* ========================================================================= *\
 * Get The Look
\* ========================================================================= */
.get-the-look-container {
  height: 350px; }

.get-the-look-text {
  font-size: 12px;
  padding: 10px; }

/* ========================================================================= *\
 * Large Orders 
\* ========================================================================= */
#largeOrders th {
  font-size: 20px; }

#largeOrders td {
  font-size: 14px; }

/* ========================================================================= *\
 * Forgot Password 
\* ========================================================================= */
#forgotPassword {
  position: relative;
  top: 2px; }

/* ========================================================================= *\
 * Lovely Press
\* ========================================================================= */
#weddingEtiquette a {
  color: #000000; }

#lovelypress h3 {
  font-size: 20px; }

#lovelypress .col-md-12 {
  border: 10px; }

.productVariantDimensions {
  font-size: 12px;
  height: 50px; }

.lp-container {
  height: 500px; }

.lp-container-small {
  height: 350px; }

.lp-header-left {
  display: block;
  position: relative;
  left: -14px; }

.lp-header-right {
  display: block;
  position: relative;
  left: 14px; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 800px) {
  .lp-header-left,
  .lp-header-right {
    left: initial;
    margin: auto; }
  #lovelypress .or-text {
    top: 10px !important; } }

/* ========================================================================= *\
 * Braintree
\* ========================================================================= */
#payentry {
  margin-bottom: 40px; }

#paypal-container {
  margin-bottom: 40px; }

.p-notice {
  margin-bottom: 20px; }

.bt-form-group {
  margin-bottom: 10px; }

.bt-form-group:after {
  content: ' ';
  display: table;
  clear: both; }

.bt-hosted-field {
  float: left;
  width: 250px;
  height: 30px;
  padding: 0 5px;
  border: 1px solid #A6A6A6; }

.bt-form-group label {
  float: left;
  width: 160px !important;
  margin-top: 5px; }

.bthf-number {
  width: 200px; }

.bthf-expirationMonth {
  width: 35px; }

.bthf-expirationYear {
  width: 60px; }

.bthf-cvv {
  width: 55px; }

.bthf-divider {
  float: left;
  margin: 0 7px;
  font-size: 20px; }

.bthf-error {
  border-color: red; }

.bt-error-message {
  color: red; }

/* ========================================================================= *\
 * Responsive Header
\* ========================================================================= */
#dd-touch {
  color: #31708E;
  font-size: 20px;
  top: 5px; }
  #dd-touch:hover {
    color: #539FBC; }

.li-responsive-header a,
.responsive-header-dropdown a {
  color: #3a3a3c; }
  .li-responsive-header a:hover,
  .responsive-header-dropdown a:hover {
    color: #31708E; }

.responsive-header-dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: block;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 2001;
  text-align: left; }

.responsive-header-dropdown:hover .dropdown-content,
.responsive-header-dropdown:focus .dropdown-content {
  visibility: visible;
  opacity: 1; }

.li-responsive-header {
  display: inline-block;
  padding-left: .5em;
  padding-top: 4px; }

#sample-affiliate-banners img {
  padding-top: 10px; }

.responsive-cart {
  font-size: 20px;
  top: 5px; }

/* ========================================================================= *\
 * Clipart
\* ========================================================================= */
.clipartTitle {
  position: relative;
  left: 25px;
  top: 7px; }

.clipartContainer {
  height: 140px; }

/* ========================================================================= *\
 * FAQ
\* ========================================================================= */
#faq .faqHeader {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px; }

#faq .faqQuestion {
  position: relative;
  left: 25px;
  width: 95%;
  display: block; }

#faq .faqAnswer {
  font-size: 14px; }
  #faq .faqAnswer ul li {
    color: #000000 !important;
    list-style: none;
    padding-top: 10px; }

@media (max-width: 600px) {
  #faq .faqHeader {
    font-size: 87%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px; }
  #faq span:nth-last-of-type(odd) {
    width: 85%;
    display: block; }
  #faq span.faqQuestion {
    height: auto;
    font-size: 10pt;
    top: auto; }
  #faq .faqAnswer {
    font-size: 14px; } }

/* ========================================================================= *\
 * Drop downs
\* ========================================================================= */
#header-account-settings {
  margin-top: 13px; }
  #header-account-settings #my-account .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden; }
  #header-account-settings #my-account:hover .dropdown-menu {
    opacity: 1;
    visibility: visible; }
    #header-account-settings #my-account:hover .dropdown-menu a:hover {
      background: #d9d9d9; }

.menudropdownblock .dropdown-menu {
  display: block;
  z-index: 99 !important;
  top: 50px;
  visibility: hidden;
  opacity: 0; }

.dropdown:hover .dropdown-menu {
  z-index: 99;
  visibility: visible;
  opacity: 1; }

.dropdown-menu.categories-left {
  width: 600px; }

.dropdown-menu.first-category-left {
  width: 900px; }

.dropdown-menu.categories-right-small {
  width: 200px;
  color: #000000;
  text-align: center; }

.dropdown-menu.categories-right-medium {
  width: 400px;
  color: #000000; }

.dropdown-menu caterogies-move-right-small {
  width: 600px; }

.dropdown-menu h4 {
  font-family: Noto Sans;
  font-size: 12px;
  line-height: 15px; }

.adjust-header-top {
  position: relative;
  top: 2px; }

#menu {
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #000000;
  min-width: 0px;
  width: 100%; }

#menu > li > span {
  display: block;
  padding: 5px 10px;
  color: #000000; }

/* Second Level */
/*
#menu > li:hover > span {
    color:#000000;
    z-index: 100;
}

#menu > li > ul{       
    display:block;
    visibility: hidden;
    left: 100%;
    top: 0px;
    background:#ffffff;
    color: #000000;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    opacity: 0;
    position:absolute;
    padding:5px 10px;
    @extend .trans;
    &:hover {
        display:block;
        visibility: visible;
        width:150px;
        opacity: 1;
        @extend .trans;
    }

}

#menu > li:hover > ul{
    display:block;
    visibility: visible;
    width:175px;
    opacity: 1;
    @extend .trans;
}
#menu > li > ul > li > span{
    display:block;
    padding:3px 10px;
}

#menu > li > ul > li:hover > span{
    color:#fff;
}
*/
ul.submenu a {
  text-decoration: none; }

ul.submenu a:hover {
  color: #31708E; }

.menu-first-margin {
  margin-top: 5px; }

/* ========================================================================= *\
 * Share/Fave/Pinit Buttons
\* ========================================================================= */
.share-saved-designs {
  width: 100px;
  margin: auto; }

.faveButton {
  background-image: url("/images/fave.png");
  background-repeat: no-repeat;
  vertical-align: top; }

.shareButton {
  background-image: url("/images/share.png");
  background-repeat: no-repeat; }

.shareButton img {
  width: 1px !important;
  height: 1px !important;
  float: left;
  vertical-align: top; }

.unfaveButton {
  background-image: url("/images/unfave.png");
  vertical-align: top;
  background-repeat: no-repeat; }

.pinitButton {
  background-image: url("/images/pinit.png");
  background-repeat: no-repeat;
  vertical-align: top; }

.faveButton,
.unfaveButton,
.shareButton {
  border: none;
  float: left;
  height: 24px;
  width: 45px;
  margin-right: 6px;
  background-color: #FFF;
  background-size: 100% auto; }

.pinitButton {
  border: none;
  float: left;
  height: 24px;
  width: 41px;
  margin-right: 6px;
  background-color: #FFF;
  background-size: 100% auto; }

/* ========================================================================= *\
 * Top 25 Favorite Things
\* ========================================================================= */
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default; }

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0; }

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in; }

.hovereffect:hover img {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
  filter: grayscale(1) blur(3px);
  -webkit-filter: grayscale(1) blur(3px);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2); }

.hovereffect .info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  margin: 50px 0 0 0;
  background-color: transparent; }

.hovereffect .info {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  opacity: 0;
  filter: alpha(opacity=0);
  color: #000000;
  text-transform: uppercase; }

.hovereffect:hover .info {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

#top-25-fav .carousel-control.right,
#top-25-fav .carousel-control.left {
  background: none !important; }

#top-25-fav .carousel-control {
  opacity: 1;
  color: #000000; }

#top-25-fav .carousel-control:hover {
  color: #d9d9d9; }

#top-25-fav ol.carousel-indicators {
  bottom: -10px; }

#top-25-fav .carousel-indicators li {
  border: 1px solid #000000; }

#top-25-fav .carousel-indicators .active {
  background: #e6e6e6; }

.adjust-error-dateneeded {
  position: relative;
  left: -145px; }

.pricingInfo {
  padding-top: 5px; }

/* ========================================================================= *\
 * SHIPPING
\* ========================================================================= */
.modal-btn {
  background-color: white;
  border: 1px solid #8c8c8c;
  border-radius: 5px; }

.modal-btn:hover {
  background-color: #ededed; }

#about-us-shipping-page a {
  color: #3a3a3c; }
  #about-us-shipping-page a:hover {
    color: #31708E; }

#about-us-shipping-page .col-md-12,
#about-us-shipping-page .col-md-6 {
  padding-left: 0px;
  padding-right: 0px; }

/* ========================================================================= *\
 * Bread Crumbs
\* ========================================================================= */
#wo-breadcrumbs {
  background-color: #737373;
  position: relative;
  left: -5px;
  height: 25px; }

.breadcrumb-text {
  position: relative;
  top: 3px;
  color: #ffffff !important; }

/* ========================================================================= *\
 * Password Reset 
\* ========================================================================= */
#fos_user_resetting_form_new_first,
#fos_user_resetting_form_new_second {
  border: 1px solid #ccc;
  border-radius: 4px; }

#fos_user_resetting_form label {
  width: 200px; }

#fos_user_resetting_form #fos_user_resetting_form_new_second,
#fos_user_resetting_form #fos_user_resetting_form_new_first {
  width: 250px; }

/* ========================================================================= *\
 * Bootstrap Classes Modification 
\* ========================================================================= */
.dropdown-menu {
  top: 80%; }

.btn, .ui-widget .ui-dialog-buttonset button {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  border: none; }

.container-fluid {
  background: #ffffff; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 800px) {
  div.blockUI.blockMsg.blockPage {
    width: 96% !important;
    left: 2% !important;
    margin: auto !important; } }

#inventory {
  display: flex;
  justify-content: space-between; }
  #inventory table.sortable th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):after {
    content: " \25B4\25BE"; }
  #inventory table th {
    background: #888888; }
  #inventory table th, #inventory table tr {
    min-width: 100px;
    padding: 10px; }
  #inventory table td {
    padding: 10px; }

@media (max-width: 600px) {
  #inventory {
    flex-direction: column;
    align-items: center; }
    #inventory table {
      margin: auto; } }

/* ========================================================================= *\
 * News Settings
\* ========================================================================= */
#news {
  padding-top: 10px; }
  #news a {
    color: #31708E; }
    #news a:hover {
      color: #539FBC; }
  #news .sidebar-header {
    font-size: 26px;
    padding-top: 10px; }
  #news .col-md-12,
  #news .col-md-10,
  #news .col-md-2 {
    padding: 0; }
  #news .news-category {
    padding-top: 10px;
    padding-bottom: 10px; }
  #news p img {
    text-align: center;
    margin: auto;
    height: auto !important;
    width: 100% !important; }

/* ========================================================================= *\
 * News Category
\* ========================================================================= */
#news-category {
  padding-top: 10px; }
  #news-category a {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #31708E; }
    #news-category a:hover {
      color: #539FBC; }

/* ========================================================================= *\
 * Media Queries
\* ========================================================================= */
/* ========================================================================= *\
 * Become A Dealer 
\* ========================================================================= */
#become-a-dealer label {
  padding-top: 10px; }

#become-a-dealer .col-md-12 {
  padding: 0px; }

#become-a-dealer .issuuembed {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  max-width: 100%; }

#become-a-dealer input[type=submit] {
  background: #31708E;
  color: #ffffff; }
  #become-a-dealer input[type=submit]:hover {
    background: #539FBC; }

#become-a-dealer .checkbox,
#become-a-dealer .radio {
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
  position: auto; }

#become-a-dealer .radio {
  padding-right: 10px; }

#become-a-dealer #upgradeForm .upgradeCopy {
  width: 500px; }

#become-a-dealer #upgradeForm label {
  padding-left: 10px; }

#become-a-dealer #upgradeForm .radio {
  padding-right: 10px; }

#become-a-dealer .preferredcontactmethodcopy {
  width: 190px; }

#become-a-dealer #becomeadealercarousel {
  padding: 15px; }

#become-a-dealer .carousel-control.left,
#become-a-dealer .carousel-control.right {
  background-image: none; }

#becomeADealer {
  margin: auto; }

/* ========================================================================= *\
 * Customer Service
\* ========================================================================= */
#contact-us a {
  color: #31708E; }
  #contact-us a:hover {
    color: #539FBC; }

#contact-us .col-md-12 {
  padding-left: 0px;
  padding-right: 0px; }

/* ========================================================================= *\
 * Site Map
\* ========================================================================= */
#sitemap a {
  font-size: 20px;
  padding-top: 10px;
  color: #31708E; }
  #sitemap a:hover {
    color: #539FBC; }

/* ========================================================================= *\
 * Custom Art
\* ========================================================================= */
#customArt a {
  color: #31708E; }
  #customArt a:hover {
    color: #539FBC; }

#customArt #customArtExamples {
  display: flex; }

/* ========================================================================= *\
 * Request Samples 
\* ========================================================================= */
#requestSamples table tr th,
#requestSamples table tr td {
  padding-right: 10px;
  padding-top: 10px; }

/* ========================================================================= *\
 * JOBS
\* ========================================================================= */
#Jobs #jobsList .job a {
  color: #31708E; }
  #Jobs #jobsList .job a:hover {
    color: #539FBC; }

#Jobs #employeeTestimonials {
  padding-top: 10px; }
  #Jobs #employeeTestimonials .employeeTestimonialContent {
    padding-top: 10px; }
    #Jobs #employeeTestimonials .employeeTestimonialContent .employeeName {
      font-size: 24px;
      padding-bottom: 10px; }
    #Jobs #employeeTestimonials .employeeTestimonialContent .employeePosition {
      padding-top: 10px;
      font-weight: bold; }

/* ========================================================================= *\
 * Custom Design Service
\* ========================================================================= */
#designServices a {
  color: #000000; }
  #designServices a:hover {
    color: #539FBC; }

/* ========================================================================= *\
 * Template Downloads
\* ========================================================================= */
#template-download .template-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  #template-download .template-content .template-column {
    width: 25%; }

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media (max-width: 800px) {
  #template-download h1 {
    text-align: center; }
  #template-download .template-content {
    text-align: center; }
    #template-download .template-content .template-column {
      width: 50%; } }

/* ========================================================================= *\
 * Register Page
\* ========================================================================= */
#register label {
  font-size: 12px; }

/* ========================================================================= *\
 * Demo Header 
\* ========================================================================= */
#demo-header {
  background: #fffee5; }
  #demo-header p {
    padding: 10px; }
  #demo-header a {
    color: #000000;
    text-decoration: underline; }

/* ========================================================================= *\
 * Demo Version Page
\* ========================================================================= */
#demo input {
  margin-top: 0px; }

#demo .form-control {
  display: inline-block;
  width: 30%; }

#demo .clear-demo {
  display: none; }

/* ========================================================================= *\
 * Media Queries
\* ========================================================================= */
@media (max-width: 800px) {
  #demo input {
    margin-top: 0px; }
  #demo .form-control {
    display: inline-block;
    width: 100%; }
  #demo .clear-demo {
    padding-bottom: 10px;
    display: block; } }

/* ========================================================================= *\
 * Our Services
\* ========================================================================= */
#our-services #services {
  display: flex;
  justify-content: center; }

/* ========================================================================= *\
 * Affiliate Program
\* ========================================================================= */
#sitemap a {
  color: #000000;
  font-size: 14px; }
