/* ========================================================================= *\
 * Responsive Header
\* ========================================================================= */

#dd-touch {
    color: $dark-teal; 
    font-size: 20px;
    top: 5px;
    &:hover {
        color: $teal;
    }
}
.li-responsive-header,
.responsive-header-dropdown {
    a {
        color: $dark-grey;
        &:hover {
            color: $dark-teal
        }
    }
}
.responsive-header-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 2001;
    text-align:left;
}

.responsive-header-dropdown:hover .dropdown-content,
.responsive-header-dropdown:focus .dropdown-content
 {
    visibility: visible;
    opacity: 1;
}

.li-responsive-header{
    display:inline-block;
    padding-left:.5em;
    padding-top:4px;
}

#sample-affiliate-banners img {
    padding-top:10px;
}

.responsive-cart {
    font-size: 20px;
    top: 5px;
}
 