/* ========================================================================= *\
 * FAQ
\* ========================================================================= */

#faq {
   .faqHeader {
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
   }
   .faqQuestion {
      position: relative;
      left: 25px;
      width: 95%;
      display: block;
   }
   .faqAnswer {
      font-size: 14px;
      ul li {
         color: #000000 !important;
         list-style: none;
         padding-top: 10px;
      }
   }
}
@media (max-width:600px) {
   #faq {
      .faqHeader {
         font-size: 87%;
         height: auto;
         padding-top: 5px;
         padding-bottom: 5px;
      }
      span:nth-last-of-type(odd) {
         width: 85%;
         display: block;
      }
      span.faqQuestion {
         height: auto;
         font-size: 10pt;
         top: auto;
      }
      .faqAnswer {
         font-size: 14px;
      }
   }
}