/* ========================================================================= *\
 * Editor  
\* ========================================================================= */

.info-box-pad{
    padding-bottom:10px;
}

#button-editor-help {
    position: relative;
    left: 75%;
    .btn {
        &:hover {
            background: $teal;
            
        }
    }
}

h3.showLayerName.ui-accordion-header.ui-helper-reset.ui-state-default.ui-corner-all,
h3.showLayerName.ui-accordion-header.ui-helper-reset.ui-state-active.ui-corner-top
{
    height: 50px;
}
#editor {
    .sectionTitle,
    .colorChoices {
        .caption {
            padding-left: 20px !important;
        }
        .image,
        .img {
            width: 110px;
        }
    }
}

#productTabs { 
    .colorChoices {
        img {
            border: 1px solid black;
        }
    }
}