/* ========================================================================= *\
 * Orders
\* ========================================================================= */
table#past-orders {
    td, th {
        padding: 10px;
        width: 140px;
    }
    thead {
        th {
            border-bottom: 2px solid $dark-grey;
        }
    }
}