/* ========================================================================= *\
 * Lovely Press
\* ========================================================================= */
#weddingEtiquette {
    a {
        color: $black;
    }
}
#lovelypress h3 {
    font-size:20px;
}

#lovelypress .col-md-12 {
    border: 10px;
}

.productVariantDimensions {
    font-size: 12px;
    height: 50px;
}

.lp-container {
    height: 500px;
}

.lp-container-small {

    height:350px;
}

.lp-header-left {
    display: block;
    position: relative;
    left: -14px;
}
.lp-header-right {
    display: block;
    position: relative;
    left: 14px;
}
  
/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media(max-width:800px) {
    .lp-header-left,
    .lp-header-right {
        left: initial;
        margin: auto;
    }
    #lovelypress .or-text {
        top: 10px !important;
    }
}