/* ========================================================================= *\
 * JOBS
\* ========================================================================= */
#Jobs {
    #jobsList {
        .job {
            a {
                color: $dark-teal;
                &:hover {
                    color: $teal;
                }
            }
        }
    }
    #employeeTestimonials {
        padding-top: 10px;
        .employeeTestimonialContent {
            padding-top: 10px;
            .employeeName {
                font-size: 24px;
                padding-bottom: 10px;
            }
            .employeePosition {
                padding-top: 10px;
                font-weight: bold;
            }
        }
    }
}