/* ========================================================================= *\
 * Password Reset 
\* ========================================================================= */
#fos_user_resetting_form_new_first,
#fos_user_resetting_form_new_second {
    border: 1px solid #ccc;
    border-radius: 4px;
}

#fos_user_resetting_form {
    label {
        width: 200px;
    }
    #fos_user_resetting_form_new_second,
    #fos_user_resetting_form_new_first {
        width: 250px;
    }
}