/* ========================================================================= *\
 * Custom Design Service
\* ========================================================================= */
#designServices {
    a {
        color: $black;
        &:hover {
            color: $teal;
        }   
    }
}