/* ========================================================================= *\
 * Masthead
\* ========================================================================= */
.ar-en-masthead {
    display: flex;
    justify-content: center;
    p {
        margin: 0px; 
    }
    img {
        @extend .trans;
        &:hover {
            opacity: .7;
            @extend .trans;
        }
    }
    h3.header-menu-full {
        a {
            position: relative;
            top: -15px;
        }
    }
    .news-link {
        color: $dark-grey;
        &:hover {
            color: $dark-teal;
        }
    }
    .more-info {
        a {
            &:hover {
                background: $dark-teal;
                text-decoration: none;
            }
        }
    }
    .dark-blue-text {
        &:hover {
            color: $teal;
        }
    } 
}

/* ========================================================================= *\
 * Nav Items
\* ========================================================================= */
.menuContainter{
    display: flex;
    justify-content: space-between;
}

a.menuCategory {
    margin: auto;
    z-index: 1000;
}

.navbar {
    border: none;
}
.nav.navbar-nav{
    font-family: Noto Sans;
    font-size: 12px;
}
.form-control.header{
    -webkit-border-radius: 0 !important;
     -moz-border-radius: 0 !important;
          border-radius: 0 !important;
}
.nav > li.fix > a {
    padding: 15px 5px !important;
    margin-right: 0 !important;
}
.menudropdownblock{
    display: flex;
    background: $dark-grey;
    min-height: 50px;
    font-size: 11px;
	line-height: 13px;
	font-family:Noto Sans;
    width:140px;
    padding:4px;
    text-align:center;    
    z-index:1;
    
}

a.menuCategory{
 	display:block;
 	color: $white;

}
.menuCategory:visited {
    color: $white; 
}
.menudropdownblock:hover,
.menudropdownblock:hover > .menuCategory:link,
.menudropdownblock:hover > .menuCategory:visited{
    background:$dark-teal;
    color: $white;
    text-decoration: none;
    
}
.menudropdownblock {
    .submenu {
        a {
            color: $black;
        }
    }
}

.header-li-right {
    padding-right:20px;
}
 
ul.nav li a, ul.nav li a:visited {
    color: $dark-teal !important;
}

ul.nav li a:hover, ul.nav li a:active {
    color: $dark-grey !important;
}

ul.nav li.active a {
    color: $dark-teal !important;
}

.collapse.navbar-collapse {
    background: $white;
}

.collapse.navbar-collapse.nav-right{
    padding-left:10px;
}
.nav>li>a.social-icons{
    padding:0px 10px 0px 0px;
}

.float-nav-left a:link,
.float-nav-left a:visited
{
    color: $dark-grey;
    text-decoration:none;
    

}  
.float-nav-left{
    float:left; 
    a {
        &:hover {
            color: $dark-teal;
            
        }
    }
}

#menu-nav-napkins-matches-and-barware{
    display:none;  
}
#menu-nav-favors-and-accessories{
    display:none;
}
#menu-nav-tabletop-and-stationery{
    display: none;
}
#menu-nav-save-the-dates{
    display:none;
}
#menu-nav-shop-by-occasion{
    display:none;
}
#menu-nav-gifts{
    display:none;
}
#menu-nav-get-the-look{
    display:none;
}
#menu-nav-ideas-and-tips{
    display:none;
}
.menu, ol, ul {
    font-size: 12px;
    padding: 0 0 0 5px;
	text-decoration: none;
	list-style:none;
} 
.nav-cat-main .h3{
		color: $dark-teal;
}
.button, input, select, textarea {
	padding: 5px;
	margin-top: 5px;
}

.pad-top-header {
    padding-top: 20px;
}

.can-coolers {
    top: 55px !important;
}

.stir-sticks {
    top: 85px !important;
}

.gift-set {
    top: 65px !important;
}

.quick-ship {
    top: 79px !important;
}

.blank-napkins {
    top: 85px !important;
}

.active > .dark-blue-bg {
    background: $teal;
}

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media (min-width: 1200px) {
    .menudropdownblock{
        width:175px;
        font-size:12px;        
    }
}
@media(max-width:1200px) {
    .float-nav-left {
        font-size:10px;
    }
}


@media (max-width: 1000px) {
    .nav-cat-main{
        height:400px;
    }
    .nav-cat-links{
        display:none;
    }
    .account-options {
        justify-content: center;
    }
    .more-info { 
        a {
            display: block;
            width: 125px;
            text-align: center;
        }
     }
}

@media(max-width:800px) {
    .li-responsive-header {
        padding-right: 10px !important;
    }
}

@media (max-width: 600px) {
    .ar-en-masthead {
        padding-top: 20px;
        p {
            font-size: 10px;
        }
        .pad-all {
            padding: 5px;
        }
    }
    .more-info {
        text-align: center;
        a {
            width: 50%;
            margin: auto;
        }
        .pad-all {
            display: block;
            text-align: center;
        }
    }
}
