/* ========================================================================= *\
 * Account Settings
\* ========================================================================= */
#account-settings-header {
    .account-options {
        display: flex;
        flex-flow: row wrap;
        div {
            padding-right: 5px;
            padding-bottom: 25px;
        }
        a {
            &:hover {
                background: $teal;
            }
        }
    } 
}

/* ========================================================================= *\
 * Profile Page
\* ========================================================================= */
.profile-pad { 
    padding-top: 30px;
}  

#user_profile {
    p {
        font-size: 13px;
    }
    input[type=submit] {
        margin-top: 30px;
    }
    .col-md-6 {
        padding: 0;
        :first-child {
            padding-right: 20px;
        }
    }  
    a,
    input[type=submit] {
        background: $dark-teal;
        
        &:hover {
            background: $teal;
           
        }
    }
}

#changePassword {
    .col-md-6 {
        padding: 0;
    }
    .pad-right.col-md-6 {
        padding-right: 10px;
    }
    input[type=submit] {
        background: $dark-teal;
        
        &:hover {
            background: $teal;
            
        }
    }
}
 
/* ========================================================================= *\
 * Past Orders
\* ========================================================================= */
#past-orders {
    a {
        color: $dark-teal;
       
        &:hover {
            color: $teal;
           
        }
    }
}

/* ========================================================================= *\
 * Account Settings
\* ========================================================================= */
#account-settings {
    input[type=submit] {
        background: $dark-teal;
        &:hover {
            background: $teal;
        }
    }
    label {
        padding-right: 10px;
    }
}


/* ========================================================================= *\
 * Reference Documents
\* ========================================================================= */
#documents {
    a {
        color: $dark-teal;
        
        &:hover {
            color: $teal;
            
        }
    }
}

 
/* ========================================================================= *\
 * Price List
\* ========================================================================= */
#price-list {
    a {  
        color: $dark-teal;
        &:hover {
            color: $teal;
        }
    }
}

#loginForm {
    a,
    input[type=submit] {
        &:hover {
            background: $teal;
        }
    }
}

/* ========================================================================= *\
 * Media Queries
\* ========================================================================= */
@media(max-width:800px) {

    #user_profile { 
        .col-md-6 {
            padding: 0;
            :first-child {
                padding-right: 0;
            }
        }  
    }
    
    #account-settings-header {
        .account-options {  
            div {
                padding-right: 5px;
                padding-bottom: 25px;
            }
        }
    }
}