/* ========================================================================= *\
 * Bread Crumbs
\* ========================================================================= */
#wo-breadcrumbs {
    background-color: #737373;
    position: relative;
    left: -5px;
    height:25px;
}
.breadcrumb-text {
    position: relative;
    top: 3px;
    color: #ffffff !important;
}