/* ========================================================================= *\
 * Drop downs
\* ========================================================================= */

#header-account-settings {
    margin-top: 13px;
    #my-account {
        .dropdown-menu {
            display: block;
            opacity: 0;
            visibility: hidden;
        }
        &:hover {
            .dropdown-menu {
                opacity: 1;
                visibility: visible;   
                a {
                    &:hover {
                        background: $light-grey;
                    }
                }
            }
        }
    }
}
.menudropdownblock .dropdown-menu {
    display:block;
    z-index:99 !important;
    top:50px;
    visibility: hidden;
    opacity: 0;
    @extend .trans;


}

.dropdown:hover .dropdown-menu {  
    z-index:99;
    visibility: visible;
    opacity: 1;
    @extend .trans;
}

.dropdown-menu.categories-left{
    width: 600px;
}

.dropdown-menu.first-category-left {
    width: 900px;
}

.dropdown-menu.categories-right-small{
    width: 200px;
    color:#000000;
    text-align:center;
}

.dropdown-menu.categories-right-medium{
    width: 400px;
    color:#000000;
}

.dropdown-menu caterogies-move-right-small{
    width:600px;
}

.dropdown-menu h4{
	font-family: Noto Sans;
	font-size: 12px;
	line-height: 15px;
}
.adjust-header-top {
    position:relative;
    top: 2px;
}

#menu{
    margin:0;
    padding:0;
    font-size:1em;
    color: #000000;
    min-width: 0px;
    width: 100%;
}

#menu > li > span{
    display:block;
    padding:5px 10px;
    color: $black;
} 

/* Second Level */
/*
#menu > li:hover > span {
    color:#000000;
    z-index: 100;
}

#menu > li > ul{       
    display:block;
    visibility: hidden;
    left: 100%;
    top: 0px;
    background:#ffffff;
    color: #000000;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    opacity: 0;
    position:absolute;
    padding:5px 10px;
    @extend .trans;
    &:hover {
        display:block;
        visibility: visible;
        width:150px;
        opacity: 1;
        @extend .trans;
    }

}

#menu > li:hover > ul{
    display:block;
    visibility: visible;
    width:175px;
    opacity: 1;
    @extend .trans;
}
#menu > li > ul > li > span{
    display:block;
    padding:3px 10px;
}

#menu > li > ul > li:hover > span{
    color:#fff;
}
*/

ul.submenu a {
    text-decoration: none;
}

ul.submenu a:hover {
    color: $dark-teal;
}

.menu-first-margin {
    margin-top: 5px;
}