/* ========================================================================= *\
 * Bootstrap Classes Modification 
\* ========================================================================= */
.dropdown-menu {
    top: 80%;
}
.btn {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    @extend .trans;
}

.container-fluid{
    background: $white;
}

/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */
@media(max-width:800px) {
    div.blockUI.blockMsg.blockPage {
        width:96% !important;
        left: 2% !important;
        margin: auto !important;
    }
}
