/* ========================================================================= *\
 * Jquery
\* ========================================================================= */
.ui-widget {
    .ui-dialog-titlebar-close {
        display: none;
    }
    .ui-dialog-buttonset {
        button {
            background: $dark-teal;
            color: $white;
            border: none;
            @extend .btn;
            &:hover {
                background: $teal;
            }
        }
    }
}

.ui-dialog {
    position: fixed;
    left: 40%!important;
    top: 38%!important;
}

.datepicker {
    table  {
        tr {
            td.active.active {
                background-color: $teal !important;
            }
        }
    }
}
