/* ========================================================================= *\
 * Pop Up Store
\* ========================================================================= */
.popUpStoreItem {
    height: 400px;
}

.magazine {
    position:relative !important;
    left: 25px;
    top: 7px;
}