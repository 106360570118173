/* ========================================================================= *\
 * Clipart
\* ========================================================================= */
.clipartTitle {
    position: relative;
    left: 25px;
    top: 7px;
}

.clipartContainer {
    height:140px;
}