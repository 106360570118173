/* ========================================================================= *\
 * Braintree
\* ========================================================================= */

#payentry{margin-bottom: 40px;}
#paypal-container{margin-bottom: 40px;}
.p-notice{margin-bottom: 20px;}

.bt-form-group{margin-bottom: 10px;}
.bt-form-group:after{content: ' '; display: table; clear: both;}
.bt-hosted-field{
   float: left; width: 250px; height: 30px; padding: 0 5px;
   border: 1px solid #A6A6A6;
}
.bt-form-group label{float: left; width: 160px !important; margin-top: 5px;}
.bthf-number{width: 200px;}
.bthf-expirationMonth{width: 35px;}
.bthf-expirationYear{width: 60px;}
.bthf-cvv{width: 55px;}
.bthf-divider{float: left; margin: 0 7px; font-size: 20px;}
.bthf-error{border-color: red;}
.bt-error-message{color: red;}

