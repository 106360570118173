/* ========================================================================= *\
 * Demo Header 
\* ========================================================================= */
#demo-header {
    background: $light-yellow;
    p {
        padding: 10px;
    }
    a {
        color: $black;
        text-decoration: underline;
    }
}


/* ========================================================================= *\
 * Demo Version Page
\* ========================================================================= */
#demo {
    input {
        margin-top: 0px;
    }
    .form-control {
        display: inline-block;
        width: 30%;
    }
    .clear-demo {
        display: none;
    }
}


/* ========================================================================= *\
 * Media Queries
\* ========================================================================= */
@media(max-width:800px) {
    #demo {
        input {
            margin-top: 0px;
        }
        .form-control {
            display: inline-block;
            width: 100%;
        }
        .clear-demo {
            padding-bottom: 10px;
            display: block;
        }
    }
}