/* ========================================================================= *\
 * News Settings
\* ========================================================================= */
#news {
    padding-top: 10px;
    a {
        color: $dark-teal;
        &:hover {
            color: $teal;
        }
    }
    .sidebar-header {
        font-size: 26px;
        padding-top: 10px;
    }
    
    .col-md-12,
    .col-md-10,
    .col-md-2 {
        padding: 0;
    }
    .news-category {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    p {
        img {
            text-align: center;
            margin: auto;
            height: auto !important;
            width: 100% !important;
        }
    }
}   
/* ========================================================================= *\
 * News Category
\* ========================================================================= */
#news-category {
    padding-top: 10px;
    a {
        padding-top: 20px;
        padding-bottom: 20px;
        color: $dark-teal;
        &:hover {
            color: $teal;
        }
    }
}

/* ========================================================================= *\
 * Media Queries
\* ========================================================================= */
