/* ========================================================================= *\
 * Top 25 Favorite Things
\* ========================================================================= */

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.hovereffect:hover img {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
  filter: grayscale(1) blur(3px);
  -webkit-filter: grayscale(1) blur(3px);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.hovereffect .info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  margin: 50px 0 0 0;
  background-color: transparent;
}

.hovereffect .info {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  opacity: 0;
  filter: alpha(opacity=0);
  color: #000000;
  text-transform: uppercase;
}

.hovereffect:hover .info {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

#top-25-fav .carousel-control.right,
#top-25-fav .carousel-control.left {
    background: none !important; 
}
#top-25-fav .carousel-control {
    opacity: 1;
    color: #000000;
}   
#top-25-fav .carousel-control:hover {
    color:#d9d9d9;
}
#top-25-fav ol.carousel-indicators {
    bottom: -10px;
}
#top-25-fav .carousel-indicators li {
    border: 1px solid #000000;
}
#top-25-fav .carousel-indicators .active {
    background: #e6e6e6;
}

.adjust-error-dateneeded { 
    position: relative; 
    left: -145px;
}

.pricingInfo {
    padding-top: 5px;
}
