/* ========================================================================= *\
 * Customer Service
\* ========================================================================= */
#contact-us {
    a {
        color: $dark-teal;
        &:hover {
            color: $teal;
        }
    }
    .col-md-12 {
        padding-left: 0px;
        padding-right: 0px;
    }
} 

/* ========================================================================= *\
 * Site Map
\* ========================================================================= */
#sitemap {
    a { 
        font-size: 20px; 
        padding-top: 10px;
        color: $dark-teal;
        &:hover {
            color: $teal;
        }
    }
} 