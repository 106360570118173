/* ========================================================================= *\
 * SHIPPING
\* ========================================================================= */

.modal-btn {
    background-color: white;
    border: 1px solid rgb(140, 140, 140);
    border-radius: 5px;
}
    
.modal-btn:hover {
    background-color: rgb(237,237,237);
}

#about-us-shipping-page {
    a {
        color: $dark-grey;
        &:hover {
            color: $dark-teal;
        }   
    }
    .col-md-12,
    .col-md-6 {
        padding-left: 0px;
        padding-right: 0px;
    }
    form {
        input[type=submit] {
        }
    }
}