/* ========================================================================= *\
 * Tags
\* ========================================================================= */


body{
    font-family: Noto Sans;
}

input[type=submit] {
    @extend .trans;
}

.h1, h1 {
    display: block;
    font-size: 30px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    font-weight: bold;
    font-family: Fjalla One;

}
.h2, h2 {
    display: block;
    font-size: 21px;
    font-family: Fjalla One;

}
.h3, h3 {
    display: block;
    font-size: 24px;
	font-family: Fjalla One;
}
.h4, h4 {
    display: block;
    font-size: 18px;
	font-family: Fjalla One;
}
.h5, h5 {
    display: block;
    font-size: 14px;
	font-family: Fjalla One;
    color: #222222;
}
.h6, h6 {
    display: block;
    font-size: 12px;
	font-family: Fjalla One;
    color: #555555;
}

a {
    @extend .trans;
}
/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media (max-width: 1000px) {
    body{
        padding-top:50px;
    }
}