/* ========================================================================= *\
 * Pagination  
\* ========================================================================= */
.pagination {
    .current {
        background: $dark-teal;
        color: $white;
        padding: 2px;
        &:hover {
            background: $teal;
        }
    }
    a {
        color: $dark-grey;
        &:hover {
            color: $teal;
        }
    }
}
