/* ========================================================================= *\
 * Product Variants
\* ========================================================================= */

#nav-category {
    .col-md-12, .col-md-6, .col-md-4 {
        padding: 0;
        :first-child {
            padding-right: 10px;
        }
        img {
            padding-bottom: 5px;
        }
    }
    .nav-cat-main {
        font-size: 13px;
        p {
            margin: 0px;
            max-width: 525px;
            min-height: 75px;
        }
    }
    .nav-cat-links {
        min-height: 200px;
        max-width: 450px;
        padding-top: 5px;
        display: flex;
        .variant-link {
            display: block;
            font-size: 11px;
            line-height: 11px;
            font-family: Noto Sans;
            color: $dark-grey;

        }
    }
}
#category {
    .col-md-4, .col-sm-6 {
        padding: 0;
    }
    .shop-now-button {
        background: $dark-teal;
        &:hover {
            background: $teal;
        }
    }
} 
    
.cups-info-link {
    font-weight: normal;
    font-size: 14px;
    color: $dark-grey;
    &:visited {
        color: $dark-grey;
    }
}

#variant-designs {
    .col-md-12,
    .col-md-4 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .or-text {
        position: relative;
        top: 20px;
        font-size: 35px;
        margin: auto;
    }
    .productVariantEnlarge {
        float: right;
    }
    .productVariantButtons {
        display: block;
        margin: auto;
        height: 20px;
        width: 150px;
    }
    .variant-header {
        font-family: 25px;
        background: $light-grey;
        padding-top: 5px;
        padding-bottom: 5px;
        color: $dark-grey;
        margin: auto;
        left: 3px;
    }
    #narrowChoices h3 {
        color: $dark-teal;
        font-size: 20px;
    }
    .tagName {
        color: $dark-teal;
        font-size: 16px;
    }
    .tagList {
        font-size: 12px;
        text-indent: 20px;
        a {
            color: $dark-grey;
            &:hover {
                color: $dark-teal; 
            }
        }
    }
    .design-make-header {
        background: $light-grey;
        color: $dark-grey;
    }
    .pv-container {
        height: 420px;
    }
    .tagCategories {
        background: $light-grey;
        border-radius: 10px;
        padding: 10px;
        width: 120%;
        position: relative;
        left: -10px;
    }
    .createYourOwnBar {
        background: $light-grey;
        width: auto;
        padding: 10px;
        height: auto;
        button {
            background: $dark-teal;
            &:hover {
                background: $teal;
            }
        }
        
        a {
            color: $dark-grey;
            &:hover {
                color: $dark-teal;
            }
        }
        
    } 
    .make-it-yours {
        button {
            background: $dark-teal;
            &:hover {
                background: $teal;
            }
        }
    }
}

.productVariantControlsHolder {
    margin-bottom: 10px;
}
.category-container {
    min-height: 500px;
    height: auto;
    text-align: center;
    img {
        padding: 10px;
        width: 100%;
        height: auto;
        @extend .trans;
        &:hover {
            opacity: .7;
             @extend .trans;
        }
    }
    h3 {
        height: 30px;
    }
    .category-info {
        min-height: 100px;
    }
    .longer-category-info {
        min-height: 150px;
        padding-bottom: 10px;
        padding-top: 20px;
        padding-right: 10px;
        padding-left: 10px;
    }
}
.back-to-top .greybg:hover {
    background: $light-grey;
}
/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media(max-width:1200px) {
    #nav-category {
        .nav-cat-main {
            height: auto;
        }
        .nav-cat-links {
            padding-top: 15px;
        }
        .col-md-12, .col-md-6, .col-md-4 {
            padding-right: 20px;
        }
    }
}
@media(max-width:1000px) {
    #nav-category {
        .nav-cat-main {
            height: auto;
        }
        .col-md-12, .col-md-6, .col-md-4 {
            padding-right: 25px;
        }
    }
}
@media(max-width:800px) {
    #nav-category {
        .col-md-12, .col-md-6, .col-md-4 {
            padding: 0;
            :first-child {
                padding: 0;
            }
        }
        .nav-cat-main {
            margin: auto;
            h3 {
                text-align: center;
            }
            p {
                padding-top: 10px;
            }
            img {
                display: block;
                margin-right: auto;
                margin-left: auto;
                width: 100%;
            }
        }
    }
    #category {
        .category-container {
            text-align: center;
        }
        img {
            display: block;
            margin-right: auto;
            margin-left: auto;
        }
    }
}
@media(max-width:320px) {
    .faveButton, .unfaveButton, .shareButton, .pinitButton {
        border: none;
        float: none;
        width: 22px;
    }
    .shareButton img {
        float: none;
    }
}