#inventory {
    display: flex;
    justify-content: space-between;
    table.sortable th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):after {
        content: " \25B4\25BE";
    }
    table {
        th {
            background: $grey;
        }
        th, tr {
            min-width: 100px;
            padding: 10px;
        }
        td {
            padding: 10px;
        }       
    }    
}

@media (max-width: 600px) {
    #inventory {
        flex-direction: column;
        align-items: center;
        table {
            margin: auto;
        }
    }
}