/* ========================================================================= *\
 * Request Samples 
\* ========================================================================= */
#requestSamples {
    table {
        tr {
            th, 
            td {
                padding-right: 10px;
                padding-top: 10px;
            }
        }
    }
}