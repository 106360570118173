/* ========================================================================= *\
 * Home Page
\* ========================================================================= */
.homepage-message {
    p {
        font-size: 16px;
        color: $white;
        max-width: 1200px;
        margin: 0;
    }
    a {
        color: $white;
        text-decoration: none;
    }
} 

#our-services {
    h2,
    h4 {
        font-family: Noto Sans;
    }
    .border {
        border: 1px solid $black;
        padding: 10px;
    }
    .services-outer-box{
        height:150px;
    }
    .services-inner-box{
        height:80px;
        font-size:12px;
    }
}

#aboutus {
    h1 {
        font-family: Noto Sans;
    }
}

#best-selling-products {
    h2,
    h4 {
        b, 
        i  {
            font-family: Noto Sans;
        }
    }
    img { 
        @extend .trans;
        &:hover {
            opacity: .8;
            @extend .trans;
           
            +  .ccadjust .shop-now {
                background: $teal;
                
            }
            
        }
    }
    
    .best-selling-padding {
        padding-top: 25px;
    }
    .best-selling-link{
        color:#404040;
        font-size:25px;
        font-weight:bold;
    }
    .best-sellers-header {
        b {
            font-size: 20px;
            font-family: Noto Sans;
        }
        a {
            text-decoration: none;
        }
        a:hover {
            border-bottom: 2px solid $dark-grey;
            padding-bottom: 5px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }  
    .best-selling-description {
        height: 25px;
    }
    .shop-now {
        font-size:22px;
        background: $dark-teal;
        padding-top:1%;
        padding-bottom: 2%;
	    font-family: Noto Sans;
    } 
    .carousel-caption {
        bottom: 85px; 
    }
}

#new-products {
    padding-top: 25px;
    h2, 
    h4 {
        font-family: Noto Sans; 
    }
    .products-row {
        display: flex;
        position: relative;
        top: -35px;
    }
    .new-product {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        a {
            padding: 10px;
            text-decoration: none;
            p {
                font-size: 16px;
	            font-family: Noto Sans;
	            padding: 15px;
	            margin: 0;
            }
            .copy-bg {
                width: 100%;
                margin: auto;
                position: relative;
                top: 75px;
                background: $dark-grey;
                
            }
            &:hover {
                .copy-bg {
                    background: $dark-teal;
                }
            }  
        }
    }
}
#slider {
    .sliderimage{
        color:$white !important;
        font-size:30px; 
        position: relative;
        top: 4px;
        @extend .trans;
    }
    .sliderimage:hover{
        color:$white;
        text-decoration: none;
    }
    .carousel-caption.ccadjust {
        bottom: 80px;
    }
    .carousel-indicators {
	    bottom: 3px;
    }
    h4 { 
        span {
            background: $dark-grey;
            @extend .trans;
            &:hover {
                background: $dark-teal;
                @extend .trans;
                
            }
        }
    }
}


/* ========================================================================= *\
 * Media Queries 
\* ========================================================================= */

@media(max-width:1200px) {
    #aboutus {
        .grey-bg {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    #best-selling-products {
        img {
            margin-top: -25px;
        }
         .ccadjust {
            padding-bottom: 75px;
        }
        
        .carousel-caption {
            bottom: 55px;
        }
        .best-selling-padding {
            height: 120px;
            padding-top: 15px;
        }
    }
}

@media (max-width: 1000px) {
    #our-services {
        .col-md-3.col-sm-6 {
            padding-bottom: 15px;
        }
    }
    
    #new-products {
        .new-product {
            .new-products-copy {
                p {
                    font-size: 10px;
                }
            }
        }
    }
    #slider {
        .sliderimage{
            font-size:10px;
            top: -2px;
        }
        .carousel-indicators li {
            display:none;
        }
    } 

    #best-selling-products {
        img {
            width: 360px;
            margin-top: -25px;
        }
        .ccadjust {
            padding-bottom: 0px;
        }
        .carousel-caption {
            bottom: 115px;
        }
        .best-selling-padding {
            height: auto;
             .best-selling-product-name {
                height: auto;
            }
            
        }
        .shop-now{
            width:200px;
            position:relative;
            margin:auto;
        }
    }
}

@media(max-width:800px) {
    #message {
        .homepage-message { 
            p {
                font-size:12px;
            }
        }
    }
     #homePageSlider h3{
        font-size:14px;
        padding-bottom: 5px;
    }
    #best-selling-products {
        .best-selling-padding {
            padding-top: 10px;
        }
    }
    #new-products {
        .products-row {
            flex-direction: column;
            .new-product {
                img,
                .copy-bg {
                    max-width: 360px;
                    width: 360px;
                }
                p {
                    font-size: 20px;
                }
            }  
        }
    }
    
}

@media(max-width:750px) {
  #best-selling-products {
        .best-selling-padding {
            padding-top: 35px;
        }
    }
    #slider {
        .sliderimage {
            font-size: 14px;
        }
    }
}

@media(max-width:320px) {
    #slider {
        #homePageSlider h3 {
            font-size:10px;
        }
        #homePageSlider > ol > li {
            display:none;
        }
    }
    #best-selling-products {
        .shop-now{
            width:100px;
            font-size:12px;
        }   
    }
}
